<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>全员营销</el-breadcrumb-item>
            <el-breadcrumb-item>客户关系</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>推荐人：</label>
                  <el-input v-model="nickName" placeholder="推荐人姓名" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>推荐人手机号：</label>
                  <el-input v-model="mobile" placeholder="推荐人手机号" clearable></el-input>
               </el-row>
               <el-button class="bg-gradient" @click="handleQuery(true)">搜索</el-button>
               <el-button @click="handleQuery(false)">重置</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <!-- 表格 -->
            <el-table
                  ref="mainTable" v-loading="tableLoad" border fit
                  :data="tableData" :stripe="true">
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column label="推荐人">
                  <template slot-scope="scope">
                     <p v-if="scope.row.parentMemberDTO">{{ scope.row.parentMemberDTO.nickName }}</p>
                     <p v-else>{{ scope.row.parentId }}</p>
                  </template>
               </el-table-column>
               <el-table-column label="推荐人手机号">
                  <template slot-scope="scope" v-if="scope.row.parentMemberDTO">
                     {{ scope.row.parentMemberDTO.mobile }}
                  </template>
               </el-table-column>
               <el-table-column label="被推荐人">
                  <template slot-scope="scope">
                     <p v-if="scope.row.memberDTO">{{ scope.row.memberDTO.realName || scope.row.memberDTO.nickName  }}</p>
                     <p v-else>{{ scope.row.userId }}</p>
                  </template>
               </el-table-column>
               <el-table-column label="被推荐人手机号">
                  <template slot-scope="scope" v-if="scope.row.memberDTO">
                     {{ scope.row.memberDTO.mobile }}
                  </template>
               </el-table-column>
               <el-table-column prop="bindingTime" label="绑定关系时间"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </el-row>
      </el-row>
   </section>
</template>

<script>
import { mapState } from 'vuex'
import { urlObj } from '@/api/interface'
import { marketing } from '@/api/interface/smtech'
export default {
   data(){
      return{
         // 数据列表
         tableData: [],            // 表格用户列表
         tableLoad: false,         // 表格加载效果
         limit: 1,                 // 每页数
         page: 1,                  // 当前页
         total: 0,                 // 用户列表总条目数
         action: 'add',            // 当前操作类型
         nickName: '',
         mobile: '',
         // 提示文本
         del_success: "删除成功！",
         add_success: "添加成功！",
         update_success: '修改成功！',
         userName_no_null: '用户名不能为空！',
         mobile_no_null: '手机号不能为空！',
         nickName_no_null: '昵称不能为空！',
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！'
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getRelationship()
   },
   methods: {
      // 获取会员列表
      getRelationship(){
         const url = marketing.relationship + `?limit=${this.limit}&page=${this.page}`
         let param = {
            hotelId: this.hotelInfo.id,
            'memberDTO.nickName': this.nickName,
            'memberDTO.mobile': this.mobile,
            companyId: this.hotelInfo.storeId
         }
         this.$axios.post(url, param).then(res => {
            if(res.success){
               this.tableLoad = false
               this.total = res.total
               this.tableData = res.records
            }
         })
      },
      // 搜索角色
      handleQuery(bool){
         this.page = 1
         if (bool) return this.getRelationship()
         this.nickName = ''
         this.mobile = ''
      },
      // 改变每页数
      pageChange(num){
         this.limit = num
         this.getRelationship()
      },
      // 改变当前页
      handlePaging(num){
         this.page = num
         this.getRelationship()
      }
   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.getRelationship()
            }
         },
         deep: true
      }
   }
}
</script>

<style lang="scss" scoped></style>
